import dicebarString from "utils/dicebear";
export const getProfileImage = (data) => {
  if (data !== undefined) {
    let image = !!data.picture ? data.picture : data.user.picture;
    if (image === "" || image === null || image === undefined) {
      dicebarString(data.name);
    }
    return image;
  }
};

import React from "react";
import { Button } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const AdminButton = ({ auth }) => {
  if (auth?.admin !== true) return <></>;
  return (
    <div style={{ marginRight: 10 }}>
      <Link to="/admin">
        <Button size="small" type="primary" className="sp-button">
          <LockOutlined />
        </Button>
      </Link>
    </div>
  );
};

export default AdminButton;

import React, { useState } from "react";
import { connect } from "react-redux";
import { Layout, Button, Tooltip } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined, BugOutlined } from "@ant-design/icons";
import Logo from "./Logo";
//import NavNotification from "./NavNotification";
import NavProfile from "./NavProfile";
//import NavLanguage from "./NavLanguage";
import NavPanel from "./NavPanel";
import NavSearch from "./NavSearch";
import Noticeable from "./Noticeable";
import AdminButton from "./AdminButton";

//import OnboardingSession from "./OnboardingSession";
import MailerLiteStatus from "./MailerLiteStatus";
//import ChatButton from "./ChatButton";
import { toggleCollapsedNav, onMobileNavToggle } from "redux/actions/Theme";
import { NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from "constants/ThemeConstant";
import utils from "utils";
import { useAuth } from "../../context/Auth";
import { useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
// import Gleap from "gleap";

const { Header } = Layout;

export const HeaderNav = (props) => {
  const { logout } = useAuth0();
  const { auth, setAuth } = useAuth();

  Sentry.setUser({
    email: auth.data.email,
    fullName: auth.data.name,
  });
  const feedback = Sentry.feedbackIntegration({
    showBranding: false,
    autoInject: false,
    showName: false,
    showEmail: false,
    buttonLabel: "Reportar erro",
    submitButtonLabel: "Enviar",
    cancelButtonLabel: "Cancelar",
    formTitle: "Reportar um erro",
    messagePlaceholder: "Descreve o erro que encontraste...",
    messageLabel: "Descrição do erro",
    successMessageText: "Obrigado por ajudares a melhorar a nossa plataforma!",
    isRequiredText: "Obrigatório",
    useSentryUser: {
      email: "email",
      name: "fullName",
    },
  });

  // useEffect(() => {
  // if (window.jivo_api)
  //   window.jivo_api.setContactInfo({
  //     name: auth.data.name,
  //     email: auth.data.email,
  //     description: "Producer",
  //   });
  //  }, []);

  //}, [window.jivo_api, auth]);

  const NavProfileMemo = React.memo((props) => {
    return <NavProfile auth={props.data_auth} logout={logout} />;
  });

  const {
    navCollapsed,
    mobileNav,
    navType,
    headerNavColor,
    toggleCollapsedNav,
    onMobileNavToggle,
    isMobile,
    currentTheme,
    direction,
    app,
  } = props;

  const [searchActive, setSearchActive] = useState(true);
  //const [prevNavCollapsed, setPrevNavCollapsed] = useState(navCollapsed);

  // const getWindowDimensions = () => {
  //   const { innerWidth: width, innerHeight: height } = window;
  //   return {
  //     width,
  //     height,
  //   };
  // };

  // const { width } = getWindowDimensions();
  // if (width < 1280) {
  //   toggleCollapsedNav(true);
  // }

  const onSearchClose = () => {
    // setSearchActive(false);
  };

  const onToggle = () => {
    if (!isMobile) {
      toggleCollapsedNav(!navCollapsed);
    } else {
      onMobileNavToggle(!mobileNav);
    }
  };

  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(currentTheme === "dark" ? "#00000" : "#ffffff");
    }
    return utils.getColorContrast(headerNavColor);
  };

  if (!isMobile) {
    // onSearchClose();
  }

  const navMode = mode();
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return "0px";
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    } else {
      return `${SIDE_NAV_WIDTH}px`;
    }
  };
  const SentryReportBug = () => (
    <div style={{ marginRight: 10 }}>
      <Tooltip title="Reportar um erro encontrado." placement="bottom">
        <Button
          id="report-bug"
          type="primary"
          size="small"
          danger
          onClick={() => {
            feedback.openDialog();
          }}
          icon={<BugOutlined />}
        />
      </Tooltip>
    </div>
  );

  const xmasStyle = {
    //  backgroundImage: `url("/img/xmas/SalesParkXMASNavBar.png")`,
  };

  return (
    <Header className={`app-header ${navMode}`} style={{ backgroundColor: headerNavColor, ...xmasStyle }}>
      <div className={`app-header-wrapper ${isNavTop ? "layout-top-nav" : ""}`}>
        <Logo logoType={navMode} app={app} />
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-left">
            <ul className="ant-menu ant-menu-root ant-menu-horizontal">
              {isNavTop && !isMobile ? null : (
                <li
                  className="ant-menu-item ant-menu-item-only-child"
                  onClick={() => {
                    onToggle();
                  }}
                >
                  {navCollapsed || isMobile ? (
                    <MenuUnfoldOutlined className="nav-icon" />
                  ) : (
                    <MenuFoldOutlined className="nav-icon" />
                  )}
                </li>
              )}
            </ul>
            <MailerLiteStatus data={auth?.data?.email_status} />
            <AdminButton auth={auth?.data} />
            {!isMobile && <NavSearch active={searchActive} close={onSearchClose} />}
          </div>
          <div className="nav-right">
            {app !== "wa-buddy" && (
              <>
                <Noticeable />
                {/* <OnboardingSession auth={auth} setAuth={setAuth} /> */}
                {/* <ChatButton /> */}

                <SentryReportBug />
                {/* <NavNotification /> */}
              </>
            )}

            {/* <NavLanguage /> */}
            <NavProfileMemo data_auth={auth.data} data_logout={logout} />

            <NavPanel direction={direction} />
          </div>
          {/* <NavSearch active={true} close={onSearchClose} /> */}
        </div>
      </div>
    </Header>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction } = theme;
  return { navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction };
};

export default connect(mapStateToProps, { toggleCollapsedNav, onMobileNavToggle })(HeaderNav);

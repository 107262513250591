import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "antd";
import IntlMessage from "../util-components/IntlMessage";

export default function Footer(props) {
  const apiVersion = () => {
    if (props.apiVersion !== undefined) {
      return (
        <div className="api-text" style={{ marginLeft: 5, color: "#bbbbbb" }}>
          <small> (Versão API: {props.apiVersion})</small>
        </div>
      );
    }
    return <></>;
  };

  return (
    <footer className="footer" style={{ margin: "0px 70px 0px 20px" }}>
      <div style={{ display: "flex", justifyContent: "left" }}>
        <span>
          Copyright &copy; {`${new Date().getFullYear()}`} <span className="font-weight-semibold">SalesPark</span> -
          Feito com{" "}
          <Tooltip placement="right" title="Feito com amor e muito café">
            ❤️ e ☕
          </Tooltip>{" "}
          - versão 2.4.806
        </span>
        {apiVersion()}
      </div>
      <div>
        <Link
          className="text-gray"
          to={{ pathname: "https://salespark.io/redirect/?u=general-terms-of-use" }}
          target="_blank"
        >
          <IntlMessage id="footer.general_terms_of_use" />
        </Link>
        {/* <span className="mx-2 text-muted"> | </span> */}
      </div>
    </footer>
  );
}

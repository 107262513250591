import React, { useState, useRef } from "react";
import Icon, {
  DashboardOutlined,
  AppstoreOutlined,
  AntDesignOutlined,
  FileTextOutlined,
  SearchOutlined,
  SettingOutlined,
  LoadingOutlined,
  RobotOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { AutoComplete, Input, Modal } from "antd";
import IntlMessage from "components/util-components/IntlMessage";
import { navigationConfig } from "configs/NavigationConfig";
import { getManualOptionList, getFixedOptionList, loadingSearchOptionList } from "./helper";

function getOptionList(navigationTree, optionTree) {
  optionTree = optionTree ? optionTree : [];
  for (const navItem of navigationTree) {
    if (navItem.submenu.length === 0) {
      optionTree.push(navItem);
    }
    if (navItem.submenu.length > 0) {
      getOptionList(navItem.submenu, optionTree);
    }
  }
  const manualOptionTree = getManualOptionList();

  return [...optionTree, ...manualOptionTree];
}

const optionList = getOptionList(navigationConfig());

const searchResult = (isLoading = false) => {
  const items = [];

  optionList.forEach((item) => {
    items.push({
      value: item.path,
      description: item.description,
      tags: item.tags,
      label: (
        <Link to={item.path}>
          <div className="search-list-item">
            <div className="icon">
              <Icon component={item.icon} style={{ fontSize: "20px", color: "#08c" }} />
            </div>
            <div>
              <div className="font-weight-semibold">
                {item.manual ? item.title : <IntlMessage id={item.title} />}
              </div>
              <div className="font-size-sm text-muted">{item.description || ""} </div>
            </div>
          </div>
        </Link>
      ),
    });
  });

  items.push(getFixedOptionList());
  //items.push(loadingSearchOptionList());

  return items;
};

const SearchInput = (props) => {
  const { active, close, isMobile, mode } = props;
  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);
  const [fixedOptions, setFixedOptions] = useState([]);
  const [serverOptions, setServerOptions] = useState([]);
  const inputRef = useRef(null);

  const onSelect = () => {
    setValue("");
    setOptions([]);
    setFixedOptions([]);
    setServerOptions([]);
    if (close) {
      close();
    }
  };

  const serverSearch = (searchText) => {
    setTimeout(() => {
      setServerOptions([]);
    }, 1000);

    return loadingSearchOptionList();
  };

  const onSearch = (searchText) => {
    setValue(searchText);
    setOptions(!searchText ? [] : searchResult(true));

    // setFixedOptions(!searchText ? [] : getFixedOptionList());
    // setServerOptions(serverSearch(searchText));
    // setTimeout(() => {
    //   setOptions(searchResult(false));
    // }, 2500);
  };

  const allOptions = () => {
    return [...options, ...fixedOptions, ...serverOptions];
  };

  // const autofocus = () => {
  //   inputRef?.current?.focus();
  // };

  // if (active) {
  // autofocus();
  // }

  return (
    <AutoComplete
      ref={inputRef}
      className={`nav-search-input ${isMobile ? "is-mobile" : ""} ${mode === "light" ? "light" : ""}`}
      dropdownClassName="nav-search-dropdown"
      options={options}
      onSelect={onSelect}
      onSearch={onSearch}
      value={value}
      filterOption={(inputValue, option) => {
        const normalize = (str) =>
          str
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toUpperCase();

        const normalizedInput = normalize(inputValue);
        const tagsJoined = option?.tags?.join(" ") || "";

        return (
          normalize(option?.value || "").indexOf(normalizedInput) !== -1 ||
          normalize(option?.description || "").indexOf(normalizedInput) !== -1 ||
          normalize(tagsJoined).indexOf(normalizedInput) !== -1 ||
          option?.isFixed === true
        );
      }}
    >
      <Input placeholder="Acesso rápido..." prefix={<SearchOutlined className="mr-0" />} />
    </AutoComplete>
  );
};

export default SearchInput;
